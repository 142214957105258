import React from "react"
import Layout from "../components/layout"
import { css } from "@emotion/core"
import { FiPhoneCall, FiMail } from "react-icons/fi"
import TelForm from "../components/telForm"

export default function Home() {
  return (
    <Layout>
      <section
        className="wrapper"
        css={css`
          display: flex;
          flex-direction: row;
          @media (max-width: 850px) {
            flex-direction: column;
          }
          margin-top: 20px;
          p {
            font-size: 1.3rem;
          }
          p.link {
            display: flex;
            align-items: center;
          }
          a {
            text-decoration: none;
            color: var(--primary-default);
            margin-left: 10px;
            transition: 0.2s ease-in-out all;
          }
          a:hover {
            color: black;
            transform: translateX(3px);
          }
          div.col {
            flex-basis: 50%;
            @media (max-width: 850px) {
              flex-basis: 100%;
            }
          }
        `}
      >
        <div className="col">
          <h1>Kontakt</h1>
          <p>
            Jeśli masz pytania odnośnie naszych usług zadzwoń lub napisz do nas!
          </p>
          <p className="link">
            <FiPhoneCall />
            <a href="tel:+48508115501"> 508 115 501</a>
          </p>
          <p className="link">
            <FiMail />
            <a href="mailto:reklamizer@reklamizer.pl">
              {" "}
              reklamizer@reklamizer.pl
            </a>
          </p>
          <p
            css={css`
              color: #575757;
              font-size: 1rem !important;
              font-weight: 300;
              margin-top: 25px;
            `}
          >
            REKLAMIZER Tomasz Łęcki
            <br />
            Jacyny-Onyszkiewicza 56/A
            <br />
            20-783 Lublin
            <br />
            NIP 7962702619
          </p>
        </div>
        <div className="col">
          <TelForm />
        </div>
      </section>

      {/* <ContactForm /> */}
    </Layout>
  )
}
