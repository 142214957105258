import React, { useState } from "react"
import { useFormik } from "formik"
import { css } from "@emotion/core"
import ClipLoader from "react-spinners/ClipLoader"

const TelForm = () => {
  const [formStatus, setFormStatus] = useState("idle")

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    onSubmit: async values => {
      setFormStatus("sending")
      const response = await fetch(
        "https://frosty-heyrovsky-37a8bb.netlify.app/.netlify/functions/hello",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: values.phone,
        }
      )
      console.log(response)
      const res = await response.json()
      console.log(res)
      setFormStatus("done")
    },
  })
  return (
    <form
      css={css`
        background: rgb(122, 44, 125);
        background: linear-gradient(
          40deg,
          rgba(122, 44, 125, 1) 0%,
          rgba(191, 50, 196, 1) 100%
        );
        text-align: center;
        color: white;
        padding: 10px 10px 30px 10px;
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        box-shadow: 0px 0px 7px #616161;
        @media (max-width: 850px) {
          margin-top: 20px;
        }
        p {
          flex-basis: 100%;
          font-weight: 300;
          font-size: 1.5rem;
        }
        input {
          flex-basis: 40%;
          width: 69%;
          padding: 6px 10px;
          border-radius: 2px;
          border: 0px;
          font-size: 1.2rem;
          font-weight: 300;
        }
        button {
          background-color: var(--secondary-color);
          flex-basis: 20%;
          width: 29%;
          padding: 6px 5px;
          border-radius: 2px;
          border: 0px;
          font-size: 1.2rem;
          font-weight: 300;
          margin-left: 5px;
          cursor: pointer;
          :hover {
          }
        }
      `}
      onSubmit={formik.handleSubmit}
    >
      <p>
        Wolisz, żebyśmy oddzwonili? <br />
        Zostaw swój numer.
      </p>
      {formStatus === "idle" ? (
        <>
          <input
            id="phone"
            name="phone"
            type="phone"
            placeholder="nr telefonu..."
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
          <button type="submit">Wyślij</button>
        </>
      ) : formStatus === "sending" ? (
        <ClipLoader size={45} color={"#ffffff"} loading />
      ) : (
        "Numer wysłany, oddzwonimy!"
      )}
    </form>
  )
}

export default TelForm
